html .login-title {
  font-size: 120%;
  text-align: center;
}

html .login-itxia-logo {
  margin: 1rem auto;
  max-width: 50%;
  display: flex;
}

html .login-form-forgot {
  float: right;
}

html .ant-col-rtl .login-form-forgot {
  float: left;
}

html .signup-form-button {
  width: 50%;
}

html .login-form-button {
  width: 50%;
}

html .login-by {
  float: right;
}

html .qq-oauth-logo {
  max-height: 1.5rem;
}

html .login-container {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

html .login-card {
  max-width: 580px;
  width: 90%;
  /* border-radius: 8px; */
}
