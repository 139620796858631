html .tag-dropDown {
  display: flex;
  flex-wrap: nowrap;
  padding: 8px;
}

html .tag-dropDown > * {
  margin-right: 8px;
}

html .tag-dropDown-divider {
  margin: 4px 0;
}

html .record-action:hover {
  cursor: pointer;
}

html .record-item-desc:hover {
  cursor: pointer;
}
