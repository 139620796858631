.t3Col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.t3OrderCount {
  margin-top: 8px;
  font-weight: 500;
  /* color: #404040; */
}

.t3NameLine {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  font-size: 13px;
  text-align: center;
  vertical-align: center;
}

.t3Name {
  /* color: #646464; */
}

.chartsLine {
  width: 100%;
  padding: 0 16px 0 12px;
}

.chartsLine + .chartsLine {
  margin-top: 16px;
}

.chartsLine .rank {
  width: 1.25em;
  text-align: center;
  display: inline-block;
  font-weight: bold;
  color: #f56a00;
  margin-right: 8px;
}

.chartsLine .orderCount {
  float: right;
  font-weight: 500;
}
