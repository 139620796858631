html .row-base {
  box-sizing: border-box;
  touch-action: manipulation;
  transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0) scaleX(var(--scale-x, 1)) scaleY(var(--scale-y, 1));
  transform-origin: 0 0;
}

.row-dragging {
  background: #fafafa;
}

html .row-dragging .drag-hidden > * {
  visibility: hidden;
}

html .truncate-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* 控制显示的行数 */
  /* 其他样式 */
}
