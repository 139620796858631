html #member-actions {
  margin-bottom: 16px;
}

html #member-actions > * {
  margin-right: 16px;
}

html .member-action-disable {
  color: red;
}

html .member-action-enable {
  color: #1890ff;
}
