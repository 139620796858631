.itxia-attachment {
  object-fit: cover;
  position: static;
  display: block;
  width: 100%;
  height: 100%;
}

.reply-text {
  background-color: rgba(0, 0, 0, 0.03);
  padding: 0.6em 0.6em 0.1em;
}

.attachment-container {
  padding: 3px;
  background-color: rgba(0, 0, 0, 0.02);
  display: flex;
  overflow: auto;
  flex-direction: column;
}

.not-img-atech {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.03);
  display: inline-block;
  padding: 16px 6px 6px;
  text-align: center;
}

.not-img-icon {
  font-size: 36px;
}

.reply-atech {
  width: 100px;
  height: 100px;
  border: #d9d9d9 1px solid;
  /* border-radius: 4px; */
  padding: 6px;
  margin: 6px 8px 0 0;
}

.attachment-list {
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  max-width: 433px;
  margin-top: 8px;
}

/*
-------------------------------
*/
