#announce-list {
  background-color: white;
  padding: 6px;
}

html #dash-space {
  min-height: 20px;
}

@media (min-width: 768px) {
  html #dash-container {
    display: flex;
    flex-direction: row-reverse;
  }

  html #dash-stat {
    flex: 3;
  }

  html #dash-space {
    flex: 0 0 20px;
  }

  html #dash-anno {
    flex: 5;
  }
}
