html #home {
  min-height: 100vh;
}

html #home-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px 20px;
  height: 50px;
}

html #home-header > * {
  line-height: 36px;
  margin: 0 15px;
}

#home-header-text {
  color: white;
  flex: 0 0 auto;
}

html #home-header-navi {
  flex: 1 1 auto;
  overflow: hidden;
}

html #home-content {
  padding: 20px 30px;
  flex: 1 0 auto;
}

html .ant-btn {
  border-radius: 6px;
}

html .ant-card {
  border-radius: 10px;
}

html .ant-input {
  border-radius: 6px;
}

html .ant-alert {
  border-radius: 6px;
}

html .ant-modal-content {
  border-radius: 10px;
}

html .ant-modal-header {
  border-radius: 10px;
}

html .ant-form-item-control-input-content {
  border-radius: 6px;
}

html .ant-form-item-control-input {
  border-radius: 6px;
}

html .ant-form-item-control-input-content {
  border-radius: 6px;
}

html .ant-form-item-control-input {
  border-radius: 6px;
}

html .ant-input-affix-wrapper {
  border-radius: 6px;
}

html .ant-table {
  border-radius: 10px;
}

html .ant-select {
  border-radius: 6px;
}

html .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 6px;
}

html .ant-picker {
  border-radius: 6px;
}

html .ant-menu-item {
  border-radius: 6px;
}