.itxia-back-top {
  position: fixed;
  right: 48px;
  bottom: -52px;
  transition: bottom 0.2s cubic-bezier(0.32, 0, 0.67, 0), opacity 0.5s linear;
  padding: 8px 8px 12px;
}

.itxia-back-top#fade-in {
  opacity: 0;
}

.itxia-back-top#fade-out {
  opacity: 0;
}

.itxia-back-top#show {
  opacity: 1;
}

.itxia-back-top:hover {
  bottom: -6px;
}

.itxia-back-top#hide {
  display: none;
  opacity: 0;
}

.back-top-text {
  display: block;
  color: black;
  font-size: 12px;
}

.itxia-back-top.bubble .back-top-text {
  visibility: hidden;
}
