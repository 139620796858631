html .order-card {
  display: inline-block;
  width: 100%;
  margin: 8px 0;
}

.order-desc {
  font-size: 1.2em;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 0.5em;
}

html .order-hr {
  margin: 12px 0;
}

html .order-btn-container > * {
  margin: 0.4em 0.6em;
}

html .order-status-icon {
  font-size: 1.25em;
}

html .order-private-bottom {
  margin-left: 0.6em;
}

html .order-private-bottom:hover {
  cursor: pointer;
}

html .condition-container > * {
  padding: 0 32px;
}

html .group-container {
  display: flex;
  flex-wrap: nowrap;
}

html .checkbox-all {
  white-space: nowrap;
}